import React ,{Component}from 'react';

class RegularLog extends  Component{
    constructor(props){
        super(props)
        
        this.updateDDG = this.updateDDG.bind(this)

    }
    componentWillMount(){
        console.log(this.props.data)
        var dataObj
        if(typeof this.props.data === 'string'){
            dataObj = JSON.parse(this.props.data) 
        } else {
            dataObj = this.props.data
        }
        console.log(dataObj)
        var DDR = new Date(dataObj.DDR)
        var DDGc = addDays(DDR,14)
        if(dataObj.DDG){
                DDGc = addDays(dataObj.DDG,14)
        }

        var weeksPast = weeksDiff(DDGc,new Date())


        this.props.setWeeks(weeksPast)
    }
    updateDDG(){
        console.log("ok button")
        var data = window.localStorage.getItem("patient-data");
        var dataObj = JSON.parse(data)
        var newDDG = document.querySelector("#update-DDG").value
        dataObj.DDG = newDDG
        var infoString = JSON.stringify(dataObj)
        window.localStorage.setItem("patient-data",infoString)
        
        this.props.updateFunc(dataObj)
    
    }
    render(){
        var dataObj
        if(typeof this.props.data === 'string'){
            dataObj = JSON.parse(this.props.data) 
        } else {
            dataObj = this.props.data
        }
        var DDR = new Date(dataObj.DDR)
        var DDGc = addDays(DDR,14)
        if(dataObj.DDG){
                DDGc = addDays(dataObj.DDG,14)
        }

        var DTG = addMonths(DDGc,9)
        
        console.log(DDGc)

        var dates = []
        var forDate = addMonths(DDGc,4)
        for(let i=0;i<6;i++){
            var rowDateBegin = addDays(forDate,1)
            var rowDateEnd = addMonths(forDate,1)
            //dates.push(<div>Depuis: {rowDateBegin.toLocaleDateString()} Jusqu'a: {rowDateEnd.toLocaleDateString()}</div>)
            dates.push(<div><div key={i}><strong>Examen du {i+4}ème mois :</strong> entre le {rowDateBegin.toLocaleDateString()} et le {rowDateEnd.toLocaleDateString()}</div><br/></div>)
            forDate = addMonths(forDate,1)
        }
        var trim1Begin = addDays(DDGc,60) 
        var trim1End = addDays(trim1Begin,21)
        var trim2Begin = addDays(DDGc,120)
        var trim2End = addDays(trim2Begin,35)
        var trim3Begin = addDays(DDGc,190)
        var trim3End = addDays(trim3Begin,35) 
        var risk1In = trim1Begin
        var risk1End = trim1End
        var risk2In = addDays(risk1End,1)
        var risk2End = addDays(risk2In,28) 
        var anesthesia = addDays(DDGc,210)
        var dateDeclaration = addDays(DDGc,105)
        
        return(
            <div className="text-center d-flex flex-column  responsive-width m-auto">
                {/*<div className="text-left">
                    Bonjour {dataObj.name}  <br></br>
                    DDR: {DDR.toLocaleDateString()} <br></br>
                    DDG calculè: {DDGc.toLocaleDateString()} <br></br>
                    Date terme grossesse: {DTG.toLocaleDateString()} <br></br>
                    Semaine: {weeksPast} <br></br>
                </div>*/}
                <div className="d-flex justify-content-between flex-wrap align-items-center"> 
                    <a  className="mx-auto my-2" data-toggle="modal" data-target="#exampleModal"  >
                        <span style={{fontSize:"70%",cursor: "pointer",textDecoration:"underline dotted"}} data-toggle="tooltip" data-placement="top" title="Cliquer pour modifier la date">
                            Date de debut de votre grossesse :</span> <span style={{fontSize:"70%"}}>{DDGc.toLocaleDateString()}</span>
                    </a>
                    <p className="mx-auto my-2" style={{fontSize:"70%"}}>Date de terme de votre grossesse<span> : {DTG.toLocaleDateString()}</span></p>
                </div>
                
                
                
                <div className="accordion text-decoration-none" id="accordionExample" role="button">
                    <div style={{cursor:"pointer"}} className="card w-100" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1" >
                        <div className="card-header w-100" id="heading1">
                        <h6 className="mb-0 ">EXAMENS OBLIGATOIRES</h6>
                        </div>

                        <div id="collapse1" className="collapse " aria-labelledby="heading1" data-parent="#accordionExample">
                            <div className="card-body text-left">
                            {dates}
                            </div>
                        </div>
                    </div>
                    <div style={{cursor:"pointer"}} className="card" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="card-header" id="headingTwo">
                        <h6 className="mb-0">ÉCHOGRAPHIES RECOMMANDÉES</h6>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body text-left">
                                <div><strong>1er trimestre :</strong> entre le {trim1Begin.toLocaleDateString()} et le {trim1End.toLocaleDateString()}</div>
                                <br/><div><strong>2e trimestre :</strong> entre le {trim2Begin.toLocaleDateString()} et le {trim2End.toLocaleDateString()}</div>
                                <br/><div><strong>3e trimestre :</strong> entre le {trim3Begin.toLocaleDateString()} et le {trim3End.toLocaleDateString()}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{cursor:"pointer"}} className="card" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <div className="card-header" id="headingThree">
                        <h6 className="mb-0">RISQUE (TRISOMIE 21)</h6>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="card-body text-left">
                                <div><strong>Échographie</strong> : du 1er trimestre (cf. plus haut)</div>
                                <br/>
                                <div><strong>Prise de sang</strong> : à effectuer entre le {risk1In.toLocaleDateString()} et le {risk1End.toLocaleDateString()} et/ou durant le 2nd trimestre</div> 
                                <br/>
                                <div><strong>Prise de sang</strong> : à effectuer entre le {risk2In.toLocaleDateString()} et le {risk2End.toLocaleDateString()}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{cursor:"pointer"}} className="card" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <div className="card-header" id="headingFour">
                        <h6 className="mb-0" >À SAVOIR</h6>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                            <div className="card-body text-left">
                                <div><strong>Date approximative de prise de RDV avec l'anesthesiste : <br/></strong>{anesthesia.toLocaleDateString()}</div><br/>
                                <div><strong>Date limite pour déclarer cette grossesse à la sécurité sociale : <br/></strong>{dateDeclaration.toLocaleDateString()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-link ml-auto" style={{fontSize:"70%"}} onClick={()=>{clearInfo(this.props.updateFunc)}}>Supprimer les données</button>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Changer la date de debut de grossesse</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <label>
                                Date de debut de grossesse
                            </label><br/>
                                <input type="date" defaultValue={DDGc.toISOString().substr(0, 10)} id="update-DDG" ></input>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.updateDDG}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
    
}
function clearInfo(updateFunc){
    window.localStorage.removeItem("patient-data")
    updateFunc(null)

    //window.location.reload(false)
}


function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
function addMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}
function weeksDiff(date1,date2){
    // To calculate the time difference of two dates 
    var Difference_In_Time = date2.getTime() - date1.getTime(); 
    console.log(date1)
    console.log(date2)
    
    // To calculate the no. of days between two dates 
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var Difference_In_Weeks = Difference_In_Days / 7
    return Math.ceil(Difference_In_Weeks)
}
export default RegularLog; // Don’t forget to use export default!