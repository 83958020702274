import React from 'react';

function FirstLog (props){
    var defaultDate = new Date()
    return(
        <div className="text-center d-flex flex-column  responsive-width m-auto">
            <h3 className="m-0">Je rejoins Echos & Grossesse</h3>
            <p className="text-center">L' accompagnement quotidien, administratif de ma grossesse</p>
            <input type="text" name="name" id="name" placeholder=" Mon nom"></input><br/>
            <label>Date de mes dernières règles</label>
            <input type="date" name="DDR" id="DDR" class="w-100" placeholder=" Nom" defaultValue={defaultDate.toISOString().substr(0, 10)}></input><br/>
            {/*<input placeholder=" Date de mes dernières règles" type="text" onFocus={e=>{e.target.type='date';e.target.defaultValue=defaultDate.toISOString().substr(0, 10)}} onBlur={e=>{e.target.type='text'}} name="DDR" id="DDR" className="w-100"></input><br/>*/}
            <button className="btn btn-info" id="set-data" onClick={()=>{saveInfo(props.updateFunc)}}>Envoyer</button>
            <p className="text-right" style={{fontSize:"70%"}}>
            Cette application ne stocke aucune donnée personnelle
            </p>
        </div>
    ) 
    
}

function saveInfo(updateFunc){
    var name = document.querySelector("#name").value;
    var DDR = document.querySelector("#DDR").value;
    
    console.log("click ok" + DDR)
    var info = {
        name : name,
        DDR: DDR
    }
    var infoString = JSON.stringify(info)
    window.localStorage.setItem("patient-data",infoString)
    updateFunc(info)
    //window.location.reload(false)

}

export default FirstLog; // Don’t forget to use export default!