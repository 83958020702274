import React, { Component} from 'react';
import './App.css';
import FirstLog from './components/first-log.js';
import RegularLog from './components/regular-log';
import HospitalLogo from './imgs/logo-generale-de-sante.png';

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      patientData : null,
      panelOpen:false,
      inputIn:false,
      posX:-10
    }
    this.updateState = this.updateState.bind(this)
    this.setWeeks = this.setWeeks.bind(this)
    this.updatePanel = this.updatePanel.bind(this)
    this.handleMove = this.handleMove.bind(this)
  }
  componentDidMount(){
    var data = window.localStorage.getItem("patient-data");
    this.setState({patientData:data})
  }
  updatePanel(panel){
    this.setState({panelOpen:panel})
  }
  handleMove(e){
    if(this.state.posX < 0){
      return 
    }
    var clientX = e.clientX
    if(clientX == undefined){
      clientX = e.touches[0].clientX
    }
    var panel = (clientX > this.state.posX)
    var offset = clientX - this.state.posX
    //console.log("aewi"+clientX + " " + this.state.posX)
    if(Math.abs(offset)>20){
      this.updatePanel(panel)
    }
  }
  updateState(newData){
    if(newData == null){
      this.setState({
        patientData:newData
      })
      return
    }
    var DDR = new Date(newData.DDR)
    var DDGc = addDays(DDR,14)
    if(newData.DDG){
      DDGc = addDays(newData.DDG,14)
    }
    this.setState({
      patientData:newData,
      weeks:weeksDiff(DDGc,new Date()),
      
    })
  }
  setWeeks(weeks){
    this.setState({
      weeks:weeks
    })
  }
  render(){
    var openStyle = {
      top:0,
      position:"fixed",
      height:"100%",
      width:"80%",
      right:"20%",
      backgroundColor:"white",
      transition: "all 0.5s",
      
      boxShadow: "0px 0px 67px 0px rgba(0,0,0,0.75)",
      overflow:"auto"

    }
    var closedStyle = {
      top:0,
      position:"fixed",
      height:"100%",
      width:"80%",
      right:"100%",
      backgroundColor:"white",
      transition: "all 0.5s",

      boxShadow: "0px 0px 67px 0px rgba(0,0,0,0.75)",
      overflow:"auto"

    }
    
    var status = "";
    if(!this.state.patientData){
      status = "error loading data";
    }
    //console.log("rendering app....")
    return (
      <div className="App" style={{fontFamily:"Montserrat",height:"100%"}}
      onMouseDown={e=>{this.setState({inputIn:true,posX:(e.clientX)})}}
      onMouseMove={this.handleMove}
      onMouseUp={e=>{this.setState({inputIn:false,posX:-10})}}
      onTouchStart={e=>{this.setState({inputIn:true,posX:e.touches[0].clientX})}}
      onTouchMove={this.handleMove}
      onTouchEnd={e=>{this.setState({inputIn:false,posX:-10})}}>
        <header className="text-center">
          <nav className="navbar navbar-light bg-light ">
            <div class="m-auto responsive-width d-flex justify-content-between">
              
              <span className="navbar-brand mb-0 h1">Échos & Grossesse</span>
              {this.state.patientData && 
                <span  class="btn btn-info">
                Semaine : <span class="badge badge-light">{this.state.weeks}</span>
              </span>
              }
            </div>
          </nav>
        </header>
        <section>
          
          {!this.state.patientData &&  <FirstLog updateFunc = {this.updateState}></FirstLog> }
          {this.state.patientData &&  <RegularLog data ={this.state.patientData} updateFunc = {this.updateState} setWeeks= {this.setWeeks}></RegularLog> }
        </section>
        {/*<div class="text-right">
          <button onClick={e=>{this.updatePanel(!this.state.panelOpen)}}>PANEL</button>
        </div>*/}

        <section>
        
          <div style={{top:0,position:"fixed",height:"100%",width:"100%",backgroundColor:"black",transition:"all 0.5s",opacity:this.state.panelOpen ? 0.1 : 0,pointerEvents: this.state.panelOpen ? "auto" : "none"}}></div>
          <div class="side-panel" key="side-p"
            style={this.state.panelOpen ? openStyle : closedStyle}>
            <br/>
            <h6>Cette application est mise à disposition par : </h6>
            <h5>Dr Jan T. Dobrowolski</h5>
            <p>Gynécologie - Obstétrique
            Echographie obstétricale
            Accouchement</p>
            <div className="text-left m-auto" style={{width:"80%",fontSize:"70%"}}>
              
              {/*<p>DU médicine foetale,
              DIU Echographie obstétricale et gynécologique,
              Membre du réseau périnatalité ELENA,
              Membre de l'ISUOG (International Society of Ultrasound in Obstretrics and Gynecology,
              Membre du ISPD (International Society for Prenatal Diagnosis,
              Membre du CFEF (Collége Français d'Echographie Foetale,</p>*/}
            </div>
            
            <br/>
            <h6>39 Boulevard de la Palle </h6>
              <h6> 42030 - Saint-Etienne Cedex 2</h6>
            <h6>Tel. : 04 77 41 00 91 | Fax. : 09 70 32 15 08</h6>
            <h6></h6>
            <div className="text-right">
              <div style={{height:"4rem"}}></div>
              <div className="d-inline-flex align-items-center">
              <img src={HospitalLogo} width="50" height="39"></img>
              <p className="m-3 text-left" style={{fontSize:"80%"}}>Hopital privé <br/> de la Loire</p>
            </div>
            </div>
          </div>
        </section>
      </div>
    );
  }  

}
function weeksDiff(date1,date2){
  // To calculate the time difference of two dates 
  var Difference_In_Time = date2.getTime() - date1.getTime(); 
  console.log(date1)
  console.log(date2)
  
  // To calculate the no. of days between two dates 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  var Difference_In_Weeks = Difference_In_Days / 7
  return Math.ceil(Difference_In_Weeks)
}
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export default App;
